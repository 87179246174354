// =============================================================================
// Variables
// =============================================================================
$debug:	false;

$color-black: #000;
$color-grey-dark: #6a6a6a;
$color-grey: #ddd;
$color-grey-light: #efefef;
$color-white: #fff;

$color-primary: #56004F;
$color-secondary: #D7D1C7;
$color-tertiary: #036F73;
$color-quaternary: #F49F11;

$color-text-base: #fff;
$color-text-dark: #181822;
$color-text-reversed: #fff;
$color-text-link: #fff;
$color-text-hover: #fff;

$color-facebook: #3B5998;
$color-twitter: #1DA1F2;
$color-instagram: #262626;
$color-youtube: #cc181e;
$color-linkedin: #0077B5;

$font-family-body: 'EB Garamond', "Times New Roman", serif;
$font-family-display: 'EB Garamond', "Times New Roman", serif;
$font-family-monospace: Menlo, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Consolas, monospace;

$body-background-color: $color-white;
$body-size: 18px;
$body-size-multiplier: 0.4vw;
$body-family: $font-family-body;
$body-color: $color-text-base;
$body-line-height: 1.5;

$font-size-small: .9rem;
$font-size-base: 1rem;
$font-size-lead: 1.3rem;

$font-size-h1: 2.4rem;
$font-size-h2: 2rem;
$font-size-h3: 1.8rem;
$font-size-h4: 1.6rem;
$font-size-h5: 1.4rem;
$font-size-h6: 1.2rem;

$heading-line-height: 1.2;

$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-heavy: 900;
$font-weight-headings: $font-weight-bold;

$radius: 4px;
$radius-large: 32px;

$z-scale: (
	foreground: 1,
	overlay: 10,
	navigation: 100,
	modal: 200
);

$mq-breakpoints: (
	xsmall: 21em,
    small: 30em,
    small-major: 40em,
    medium: 50em,
    medium-major: 62.5em,
    large: 62.5em,
    xlarge: 80em
);

// $mq-show-breakpoints: (xsmall, small, small-major, medium, medium-major, large, xlarge);
