// Import origin
@import "styles";

// Style guide
header {
	border-bottom: 1px solid $color-grey;
	padding: 1em 0;
}

header h1 {
	margin: 0;
}

section {
	margin: 4rem 0;
}

.subtitle {
	color: $color-grey-dark;
	text-transform: uppercase;
	font-size: $font-size-h5;
	font-weight: 400;
}

.swatch-container {
	@include mq(medium) {
		display: flex;
		justify-content: space-between;
	}
}

.swatch {
	min-height: 160px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mq(medium) {
		width: 22%;
	}
}

.swatch--primary 		{ background: $color-primary }
.swatch--secondary 		{ background: $color-secondary }
.swatch--tertiary 		{ background: $color-tertiary }
.swatch--quaternary	 	{ background: $color-quaternary }

.swatch__meta {
	font-family: $font-family-monospace;
	color: $color-text-reversed;
	text-align: center;
	font-size: $font-size-small;
}

.swatch__hex {
	display: block;
}

.icon {
	margin: 0 0.4em;
	&:hover {
		opacity: 0.8;
	}
}

.icon--twitter {
	color: $color-twitter;
}

.icon--facebook {
	color: $color-facebook;
}

.icon--youtube {
	color: $color-youtube;
}
