// =============================================================================
// Typography
// =============================================================================

p {
	margin-bottom: 1em;
	margin-top: 0;
}

p.lead {
	font-size: fluid-type($font-size-lead);
}

h1, h2, h3, h4, h5, h6 {
	font-size: 1em;
	margin-bottom: 1em;
}

.btn {
	color: $color-primary;
	background: #fff;
	border-radius: 20px;
	padding: 0.4rem 2rem;
	text-decoration: none;
	font-family: Arial;
	margin: 1rem 0 2rem 0;
	text-transform: uppercase;
	font-size: 0.9rem;

	&:hover {
		color: $color-primary;
	}
}

a {
	color: $color-text-link;
}

a:focus,
a:hover {
	color: $color-text-hover;
	text-decoration: underline;
}
