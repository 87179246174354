// =============================================================================
// Site
// =============================================================================

.global-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
}

.logo-container {
	padding: 2rem 0;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mq(medium) {
		min-height: 40vh;
	}

	@include mq(xlarge) {
		min-height: 50vh;
	}
}

.logo {
	width: 140px;

	@include mq(medium) {
		width: 180px;
	}

	@include mq(xlarge) {
		width: 250px;
	}
}

.content {
	flex: 1;
	min-height: 50vh;
	padding: 1rem;
	background: $color-primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
